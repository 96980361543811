.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 10vmin; /* 10vmin: 10 percent of the viewport smallest dimension */
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 15vh; /* 15vh: 15 percent of the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

div.mainContent{
  flex: 1;
}

div.footerContent{
  background-color: silver;
}

.App-header-logo-line {
  margin: 10px 5px;
}

.App-header-logo {
  margin: 10px 5px;
  float: left;
}

.App-logo {
  margin: 5px 2.5px;
}

.App-header-name {
  margin: 10px 5px;
  height: 10vmin;
  float: left;
  /* this will get the child vertically centered: */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-header-title {
  margin: 10px 5px;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
  color: blue;
  transition-duration: 0.5s;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
}

a:visited {
  text-decoration: none;
  color: blue;
}

a:hover {
  text-decoration: none;
  background-color: blue;
  color: white;
}

a:active {
  text-decoration: none;
  color: red;
}

@keyframes myBackgroundColor {
  from {background-color: red;}
  to {background-color: blue;}
}

@keyframes myColor {
  from {color: blue;}
  to {color: red;}
}

a.footer {
  text-decoration: none;
  transition-duration: 0.5s;
  padding: 0.1rem 0.1rem;
  border-radius: 0.3rem;
  animation: myColor 5s infinite;
  animation-direction: alternate;
}

a.footer:visited {
  text-decoration: none;
}

a.footer:hover {
  text-decoration: none;
  transition-duration: 0.5s;
  animation: myBackgroundColor 5s infinite;
  animation-direction: alternate;
}

a.footer:active {
  text-decoration: none;
  color: purple;
}

div.welcome {
  text-align: center;
}

#creepyAvatar01 {
  display: inline-block;
  filter: drop-shadow(10px 10px 4px #aaa);
}

div.creepyAvatar {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.flexiblePanel {
  display: flex;
  flex-wrap: nowrap;
}

.flexCard{
  border: 1px black solid;
  background-color: white;
  border-radius: 0.5rem;
  filter: drop-shadow(10px 10px 4px #aaa);
  margin: 2vw;
  transition-duration: 0.5s;
  text-align: center;
}

.flexCard h2 {
  font-family: 'Poller One', cursive;
}

.flexCard:hover{
  box-shadow: -0.7vw 2.4vh 2.5vh #333;
  transform: rotate(-2deg);
  transition-duration: 0.5s;
}

@media screen and (max-width: 799px) {
    
  div.welcome {
    margin-left: 20%;
    margin-right: 20%;
  }

  .flexiblePanel {
    flex-direction: column;
    justify-content: space-evenly;
    align-content: space-around;
    align-items: center;
  }

  .flexCard {
    min-width: 70vw;
    max-width: 70vw;
    padding: 0vw 5vw;
  }

}

@media screen and (min-width: 800px) {
    
  div.welcome {
    margin-left: 30%;
    margin-right: 30%;
  }

  .flexiblePanel {
    flex-direction: row;
    justify-content: space-evenly;
    align-content: space-around;
  }

  .flexCard {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0vw 5vw;
  }

}

