body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.App-logo {
  height: 10vmin; /* 10vmin: 10 percent of the viewport smallest dimension */
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 15vh; /* 15vh: 15 percent of the viewport height */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

div.mainContent{
  -webkit-flex: 1 1;
          flex: 1 1;
}

div.footerContent{
  background-color: silver;
}

.App-header-logo-line {
  margin: 10px 5px;
}

.App-header-logo {
  margin: 10px 5px;
  float: left;
}

.App-logo {
  margin: 5px 2.5px;
}

.App-header-name {
  margin: 10px 5px;
  height: 10vmin;
  float: left;
  /* this will get the child vertically centered: */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.App-header-title {
  margin: 10px 5px;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
  color: blue;
  transition-duration: 0.5s;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
}

a:visited {
  text-decoration: none;
  color: blue;
}

a:hover {
  text-decoration: none;
  background-color: blue;
  color: white;
}

a:active {
  text-decoration: none;
  color: red;
}

@-webkit-keyframes myBackgroundColor {
  from {background-color: red;}
  to {background-color: blue;}
}

@keyframes myBackgroundColor {
  from {background-color: red;}
  to {background-color: blue;}
}

@-webkit-keyframes myColor {
  from {color: blue;}
  to {color: red;}
}

@keyframes myColor {
  from {color: blue;}
  to {color: red;}
}

a.footer {
  text-decoration: none;
  transition-duration: 0.5s;
  padding: 0.1rem 0.1rem;
  border-radius: 0.3rem;
  -webkit-animation: myColor 5s infinite;
          animation: myColor 5s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

a.footer:visited {
  text-decoration: none;
}

a.footer:hover {
  text-decoration: none;
  transition-duration: 0.5s;
  -webkit-animation: myBackgroundColor 5s infinite;
          animation: myBackgroundColor 5s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

a.footer:active {
  text-decoration: none;
  color: purple;
}

div.welcome {
  text-align: center;
}

#creepyAvatar01 {
  display: inline-block;
  -webkit-filter: drop-shadow(10px 10px 4px #aaa);
          filter: drop-shadow(10px 10px 4px #aaa);
}

div.creepyAvatar {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.flexiblePanel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.flexCard{
  border: 1px black solid;
  background-color: white;
  border-radius: 0.5rem;
  -webkit-filter: drop-shadow(10px 10px 4px #aaa);
          filter: drop-shadow(10px 10px 4px #aaa);
  margin: 2vw;
  transition-duration: 0.5s;
  text-align: center;
}

.flexCard h2 {
  font-family: 'Poller One', cursive;
}

.flexCard:hover{
  box-shadow: -0.7vw 2.4vh 2.5vh #333;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
  transition-duration: 0.5s;
}

@media screen and (max-width: 799px) {
    
  div.welcome {
    margin-left: 20%;
    margin-right: 20%;
  }

  .flexiblePanel {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-content: space-around;
            align-content: space-around;
    -webkit-align-items: center;
            align-items: center;
  }

  .flexCard {
    min-width: 70vw;
    max-width: 70vw;
    padding: 0vw 5vw;
  }

}

@media screen and (min-width: 800px) {
    
  div.welcome {
    margin-left: 30%;
    margin-right: 30%;
  }

  .flexiblePanel {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-content: space-around;
            align-content: space-around;
  }

  .flexCard {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    padding: 0vw 5vw;
  }

}


.tehiMain {
    border: 0px dashed silver;
}

.tehiPara {
    -webkit-margin-after: 0px;
            margin-block-end: 0px;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
}
